<template lang="html">
	<main class="ps-page">
		<blog-hero component-title="Privacy Policy" />
		<div class="ps-page__content">
			<div class="container">
				<div class="ps-about-us">
					<div class=WordSection1>
					<p class=MsoNormal><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black;'>The Privacy Policy has been updated as of
					1/20/20. For details on exercising rights under the California Consumer Privacy
					Act, <a href="#section-7">click here to section 7.</a></span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>PLEASE READ THIS
					PRIVACY POLICY (THIS &quot;PRIVACY POLICY&quot;) CAREFULLY BEFORE USING THIS
					WEBSITE, APPLICATION OR ANY OTHER PRODUCT OR SERVICE.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>If you have a
					separate written agreement with {{ host }}, then the updates to the Terms of
					Service will not apply to you, but the updates to the Acceptable Use Policy
					will be effective after the update notice period provided in our separate
					written agreement.</span></p>
					<h2 style='margin-top:0in;margin-right:0in;margin-bottom:4.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_d3juv1k7cvi3"></a><b><span lang=EN style='font-size:17.0pt;line-height:
					139%;color:black'>Privacy Policy</span></b></h2>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>The Website
					currently located at www.{{ host }}, and the subdomains of that website,
					where this Privacy Policy is posted (collectively, the “Website”) are made
					available by on behalf of itself (collectively, “{{ host }}”, “we”, “us”, or
					“our”). We are excited to engage with you through your use of the Website and
					through our various online services and applications provided by through the
					Website (collectively, the “Services”). This policy (this “Privacy Policy”) is
					meant to help you understand our practices regarding the collection, use, and
					disclosure of personal information in connection with these Services as well as
					inform you of your rights and choices about the use of your information. We
					will not use, disclose, or share your information except as described in this
					Privacy Policy.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><b><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>This Privacy
					Policy will apply to visitors to the Website and users of any Services. Please
					remember that you are agreeing to the practices and procedures described in
					this Privacy Policy and the Terms of Service when you use the Services. If you
					provide Personal Information, you also agree that that information may be
					transferred from your current location to the offices and servers of and its
					authorized third parties. If you do not agree with this Privacy Policy and the
					Terms of Service, please do not use the Website or any Service.</span></b></p>
					<ol style='margin-top:0in' start=1 type=1>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>Types of Information We Collect</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>How We Collect Your Information</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>How We Use Your Information</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>How We May Share Your Information</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>Communication Services and Community
						Features</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>Third-Party Advertising</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>All Users: Your Choices and Controls</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>California Residents: California
						Privacy Rights</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>Security Practices</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>Links to Other Services</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>Additional Information</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>Changes to this Privacy Policy</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>Children’s Data</span></li>
						<li class=MsoNormal style=''><span lang=EN style='font-size:
						11.5pt;line-height:115%;color:black'>Exclusions</span></li>
						<li class=MsoNormal style='margin-bottom:12.0pt;'><span
						lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Contact Us</span></li>
					</ol>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_70lzqph58gr9"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>1. Types of Information We Collect</span></b></h4>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>When you interact
					with us through the Services, we collect the information you provide, usage
					information, and information about your device. We also collect information
					about you from other sources like service providers, and optional programs in
					which you participate, which we may combine with other information we have
					about you. Here are the types of information we collect about you:</span></p>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_kfmce8maci37"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(A) Personal Information</span></b></h4>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>“Personal
					Information” is information that identifies, relates to, describes, or can be
					reasonably linked to, directly or indirectly, a particular individual or
					household. We may collect Personal Information (as we have done for the past
					twelve months) from you on or through the Services in a variety of ways,
					including when you register on the Website, purchase a Service, participate in
					online contests, surveys, or games, sign-up for a newsletter or subscription,
					or purchase merchandise. We also may, from time to time, receive Personal
					Information about you from third-party sources to improve the Services, and for
					the various purposes outlined in this Privacy Policy.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We have grouped
					together the following categories of Personal Information to explain how we use
					this type of information. These terms are used throughout this Privacy Policy:</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Personal Identifiers,
					including your first and last name, birthdate, age, gender, username, and
					social security number.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Contact
					Information, including your email address, phone number, and home address.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Biographical
					Information, including your level of education and professional background.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Financial
					Information, including your credit card number and purchasing history.</span></p>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_xgatso8pqny2"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(B) Device Identifiable Information</span></b></h4>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We may collect
					information that may not reasonably identify you or a household personally, but
					is linked to your computer or device (“Device Identifiable Information”). We
					collect Device Identifiable Information from you in the normal course of
					providing the Services. When you visit our Website to browse, read, or download
					information, we automatically collect information about your computer that your
					browser sends, such as your IP address, browser type and language, access
					times, pages visited, and referring website addresses. We may use Device
					Identifiable Information to analyze trends, help administer the Services, track
					the online movement(s) of users, to learn about and determine how much time
					visitors spend on each page of the Website, how visitors navigate throughout
					the Website or use other Services, and to gather broad demographic information
					for aggregate use. We may also collect Device Identifiable Information through
					“cookies” or “web beacons” as explained below.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>In addition, we
					may collect information about your mobile device such as the type and model,
					operating system (e.g. iOS or Android), carrier name, mobile browser (e.g.
					Chrome, Safari), applications using our Website, and identifiers assigned to
					your device, such as its iOS Identifier for Advertising (“IDFA”), Android
					Advertising ID (“AAID”), or unique device identifier (a number uniquely given
					to your device by your device manufacturer), sometimes referred to as a mobile
					carrier ID.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We may also
					collect your location information, such as your zip code or the approximate
					geographic area provided by your Internet service provider (“ISP”) or location
					positioning information provided by the location services and GPS features of
					your mobile device when location services have been enabled.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>When aggregated
					or de-identified information, including Device Identifiable Information, is
					directly associated with Personal Information, such that the aggregated or
					de-identified information can be reasonably associated with a particular
					individual User or household, then this aggregated or de-identified information
					is treated as Personal Information.</span></p>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_u9vclr8q5bf3"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>2. How We Collect Your Information</span></b></h4>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_7robihid6t7e"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(A) Cookies and Web Beacons</span></b></h4>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>To help us serve
					you better, we may collect Device Identifiable Information from you through
					technologies such as “cookies” and “web beacons” (which are also called clear
					GIFs, pixel tags, or pixels) or by tracking internet protocol (“IP”) addresses.</span></p>
					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>“Cookies” are small data files that are stored by your web browser
					when you interact with a website. When you visit a webpage, the cookie sends
					back Device Identifiable Information.</span></p>
					<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
					margin-left:.5in;text-indent:-.25in;'><span lang=EN
					style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>“Web beacons” are small graphic images (usually invisible) that
					can be embedded in content and ads on a webpage that track usage of the Website
					and effectiveness of communication. These web beacons can then be used to
					recognize our cookies and to monitor certain user interactions with a website.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Cookies operate
					in the background and you can turn them off by adjusting your Web browser
					settings, but doing so may make it difficult to use some of the features on the
					Website or the features may not be available to you. We may use cookies to make
					your Website experience easier by, for example, remembering your preferences,
					keeping track of your login name, password and, if you want to buy things while
					on the Website, your “shopping cart.”</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We may also use
					cookies and other activity tracking tools on the Website and through other
					Services to collect your engagement metrics, including your Personal
					Identifiers and Device Identifiable Information, related to your activity on
					the Website and Services.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>For more
					information on your choices regarding cookies, you can review the FTC’s guide
					to opting out of online tracking for computers and mobile devices. You can also
					see the “All Users: Your Choices and Controls” section below for ways to
					opt-out.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Your browser may
					offer you a “Do Not Track” option, which allows you to signal to operators of
					websites and web applications and services that you do not want them to track
					your online activities. The Website and Services do not currently support Do
					Not Track requests at this time.</span></p>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_k2l3osn8ypsx"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(B) Other Direct Interactions with You</span></b></h4>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>You give us your
					information in your direct interactions with us. We collect information you
					provide to us when you register accounts on our Website and other Services;
					request tickets, newsletters, and other products, services, or information from
					us; respond to surveys; participate in sweepstakes or contests; or otherwise
					interact with us. This information includes Personal Identifiers, Contact
					Information, Biographical Information, Financial Information, and Device
					Identifiable Information, which you give us from time to time.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Except for IP
					addresses, we may match Device Identifiable Information with Personal
					Information that we may have from or about you, which would allow us to
					identify you. We may use this information to provide you with offers that are
					available where you physically are located.</span></p>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_c9u0oliovagn"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(C) Social Media</span></b></h4>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>When using the
					Services or when using certain third party social networking services (e.g.,
					Facebook, Twitter) (each a “Social Network”), you may have the option to
					connect your Service information and activity with your Social Network
					information and activity. If you authorize such a connection, you permit us to
					share or publish information about your Service activity with that Social
					Network and its users, and to access certain of your Social Network
					information, as disclosed to you at the time you consent to the connection.
					Information we may share with a Social Network may include technical
					information about your Service activity, your Service comments, or the videos
					you watch on the Service. Information we may access about you, with your
					consent, from a Social Network may include, but is not limited to, your basic
					Social Network information, your location data, your list of contacts, friends
					or followers and certain information about your activities on the Social
					Network. If you permit a connection between a Social Network and a Service, we
					(and that Social Network) may be able to identify you and to associate
					information received pursuant to the connection with information we already
					have about you. For more information, please review the privacy disclosures and
					terms of your Social Network account, which govern how that account information
					is collected and shared with us.</span></p>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_mr7ilc1ue4f5"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(D) Other Third Party Sources</span></b></h4>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We may also
					receive other information from Service Providers in connection with the
					operation of our Services, including when you purchase our tickets or take our
					surveys through a third-party vendor. We may also receive information from
					Service Providers through marketing and demographic studies that we use to
					supplement Personal Information and de-identified information provided directly
					by you.</span></p>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_krpm06hwa3f9"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>3. How We Use Your Information</span></b></h4>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>For the Purpose
					Collected: If you provide Personal Information for a certain reason, we will
					use the Personal Information in connection with the reason for which it was
					provided. For instance, if you make an online purchase, we will use your credit
					card information to fulfill and process your purchases. Also, if you register
					or open an account on the Website, the Personal Information you provide may be
					used to maintain your account, provide you access to certain features of our
					Website, or offer you the benefits and privileges that typically come along
					with registration. These benefits may include access to and use of exclusive or
					personalized content or activities (such as message boards) and participation
					in special events and promotions (such as contests and sweepstakes). As part of
					these activities, we may use your Personal Information to determine your
					eligibility, notify you whether you are a winner, and/or fulfill and deliver
					prizes.</span></p>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Support from
					Service Providers: In addition, Personal Information and Device Identifiable
					Information we collect may be used by us and by third-party service providers
					(as has been for the past twelve months), such as hosting providers, data
					management companies, address list hosting companies, e-mail service providers,
					analytics companies (e.g., Google Analytics), distribution companies,
					fulfillment companies, and payment processors (collectively, “Service
					Providers”) for a variety of purposes, including to:</span></p>
					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>contact you about the Website and/or Services you have requested;</span></p>
					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>provide you with Services or information you have requested, or
					products or services you have ordered or signed up for, such as newsletters;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>confirm or fulfill an order you have made;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>administer your participation in a contest, sweepstakes,
					promotion, survey, or voting poll;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>facilitate the operation, maintenance and improvement of the
					Services, our business operations, and internal record keeping; and</span></p>

					<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
					margin-left:.5in;text-indent:-.25in;'><span lang=EN
					style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>with your permission, send you promotional material or special
					offers from MLS, Member Clubs, our marketing partners or other third parties.
					If you no longer wish to receive promotional e-mails from us, or have your
					information shared with third parties as previously described, you may change
					your mind and/or your preferences by following the directions in the “All
					Users: Your Choices and Controls” section of the Privacy Policy below.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Our Service
					Providers are given the information they need to perform their designated
					functions in connection with the Services, and we do not authorize them to use
					or disclose your Personal Information for their own marketing or other
					purposes.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Identity
					Verification and Security: We also may obtain information about you from other
					businesses to verify your identity so we can properly process your requests and
					prevent fraud. By accessing and using our Services, you agree that we may use
					your submitted and obtained Personal Information and Device Identifiable
					Information for such purposes and to maintain the security of the Services.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Product
					Improvement and Marketing: may also use your Personal Information, Device
					Identifiable Information, and other personally non-identifiable information
					collected through the Site and Services to help us improve the content and
					functionality of the Site and Services to better understand our users and to
					provide customer support, to detect, to personalize control and experiences on
					our Sites and applications, and to improve the Services. may use this
					information to communicate with you regarding the Website and Services and, to
					the extent permitted by law, to tell you about services we believe will be of interest
					to you.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Enforcement and
					Legal Obligations: may use your Personal Information to enforce our Terms of
					Service, or investigate and prevent activities that may violate our policies or
					be illegal, and to respond to legal proceedings and obligations.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_v1revpvmgtc9"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>4. How We May Share Your Information</span></b></h4>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_fl12hihhwjzk"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(A) Business Transfers</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>As we develop our
					business, we might sell or buy businesses or assets, such as the sale of all or
					most of ’ or equity to another company. In the event of a corporate sale,
					merger, reorganization, dissolution, or similar event in which a third party
					assumes control of all or part of our business, Personal Information may be
					part of the transferred assets or business.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_o46la1varab8"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(B) Service Providers and Third Parties</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We share Personal
					Information and Device Identifiable Information with Service Providers, as
					described above in “How We Use Your Information”. We do not sell your Personal
					Information to third parties, whether for monetary or other valuable
					consideration.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We may share with
					third parties aggregated demographic information collected on or through the
					Services for various purposes such as fan research and audience analysis.
					Aggregated demographic information does not include and is not linked to any
					Personal Information that can identify you or another individual person but we
					may use your IP Addresses to verify users' locations for various purposes,
					including blackout restrictions.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_2ot87ie9x19x"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(D) Third-Party Marketers</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>With your
					permission, we may share your Personal Information with our marketing partners
					and other third parties so that they may contact you directly regarding special
					promotions or offers (such as subscriptions to and promotions for goods and
					services, including, for example, through participation in a co-sponsored
					sweepstakes or contest). We are not responsible for how these third parties may
					use your Personal Information, and such uses are subject to their own policies.
					If you have consented to share your Personal Information with third parties for
					such third parties’ marketing purposes, you can change your mind at any time
					and may let us know by following the instructions in the “All Users: Your
					Choices and Controls” section below.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_dyyuffdk6evz"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(E) Legal Requirements</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We may also share
					Personal Information and Device Identifiable Information under the following
					circumstances:</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>if required by law, such as by a court order, statute, regulation
					or rule, through legal process, or for law enforcement purposes;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>in the event of a bankruptcy, insolvency, reorganization,
					receivership or assignment for the benefit of our creditors;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>if we determine it necessary in connection with an investigation
					or in order to protect our legal rights; or</span></p>

					<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
					margin-left:.5in;text-indent:-.25in;'><span lang=EN
					style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>in response to requests from federal, state, local or foreign law
					and civil enforcement agencies, such as a search warrant, subpoena or court
					order.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_5uerwlmdldxq"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>5. Communication Services and Community Features</span></b></h4>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_6slag1cr6e7m"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(A) Chat Rooms, Forums, and Message Boards</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>The Services may
					make chat rooms, forums, blogs, message boards, and other community features
					and activities available to you and other users. If you post Personal
					Information online, it will be publicly available and you may receive
					unsolicited messages from other parties. We cannot ensure the security of any information
					you choose to make public in a chat room, forum, or message board. Also, we
					cannot ensure that parties who have access to such publicly available
					information will respect your privacy. Please exercise caution when deciding to
					disclose Personal Information in these areas. We reserve the right (but assume
					no obligation) to review and/or monitor any such community areas on the
					Services.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_qcwxu4c00gzv"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(B) Short Message Service</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We may make
					available a service through which you can receive messages on your wireless
					device via short message service (“SMS Service”). You understand that your
					wireless carrier’s standard rates apply to these messages.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>If you subscribe
					to one of our SMS Services, you may be required to register and provide
					Personal Information. We may also collect the date, time, and content of your
					messages in the course of your use of the SMS Service. We will use the
					information we obtain in connection with our SMS Service for the purpose of
					sending the SMS messages. If fees are charged to your wireless account invoice,
					we may provide your carrier with your applicable information to facilitate the
					transaction in connection therewith. Your wireless carrier and other service
					providers may also collect data about your wireless device usage, and their
					practices are governed by their own policies. We may also contact your carrier,
					which may access the content of your wireless account, for the purpose of
					identifying and resolving technical problems and/or Service-related complaints.
					We do not guarantee that your use of the SMS Service will be private or secure,
					and we are not liable to you for any lack of privacy or security you may
					experience. You are fully responsible for taking precautions and providing
					security measures best suited for your situation and intended use of the SMS
					Service. You may change your mind and unsubscribe to SMS Services at any time
					by following the instructions in the “All Users: Your Choices and Controls”
					section below.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_h4am16vp2l4h"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>6. Third-Party Advertising</span></b></h4>

					<span id="section-7"></span>
					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We may allow
					other companies that are providing advertising content, social networking
					services, or other services on our Website to set and access tracking
					technologies such as cookies on your computer. These companies may collect
					information about your visits to the Website in order to measure and assess the
					effectiveness of online advertising and to provide and better target
					advertisements about goods and services of interest to you. While the
					information collected by these third parties from you while you are visiting
					our Website is not personally identifiable, some of these third parties may
					also combine such de-identified information with other information they have
					collected from various other sources, such as their own past online and/or
					offline purchase information and web usage from other sites. Such other
					information may include Personal Information, as well as demographic and
					behavioral information. Those companies’ use of cookies, and the information
					collected, are governed by those companies’ privacy policies, not ours. Please
					see the &quot;&quot;All Users: Your Choices and Controls” section below for
					ways to opt-out of this third party advertising.</span></p>
					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_twfsnmows8xl"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>7. All Users: Your Choices and Controls</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>You have various
					choices with respect to the collection of and certain of our uses of the
					Personal Information we collect from you on or through the Website or Services.
					If you have not consented to us using your Personal Information as described in
					this Privacy Policy, we will only use it to complete the transaction or provide
					the service or product you have requested.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_bnxxgq3d2894"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(A) Accessing, Reviewing, Updating, Correcting or Deleting
					Your Personal Information</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>If you want to
					access, review, update, or correct inaccuracies in your Personal Information,
					or delete your Personal Information, you can:</span></p>

					<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
					margin-left:.5in;text-indent:-.25in;'><span lang=EN
					style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><b><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>contact us</span></b><span lang=EN style='font-size:11.5pt;
					line-height:115%;color:black'> via email at support@help.{{ host }}</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Upon receiving
					your communication, we will take appropriate steps to update or correct such
					information in our possession, or to remove you from our catalog and mailing
					list. Your preferences include, for example, your profile information, as well
					as any shipping, payment, and account information (if applicable), as well as
					whether you want to receive communications from us. You can access your
					preferences by logging into your account. If you want to close your account
					with us and have us delete the Personal Information in it (except for
					information that we keep for record-keeping purposes), you should </span><span
					lang=EN style='color:black'><a href="https://braingasms.com/privacy"><b><span
					style='font-size:11.5pt;line-height:115%;color:#005D77;text-decoration:none'>contact
					us</span></b></a></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'> via mail at support@help.{{ host }}. We may request certain
					Personal Information for the purposes of verifying the identity of the
					individual seeking access to their personal information records.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_oeoby93p438a"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(B) Opting Out of Direct Marketing and Other Advertising</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>If you have
					agreed to receive communications or solicitations from us (and signed up for an
					account with us), and you later change your mind, you can revise your
					preferences by contacting us at support@help.{{ host }}. You also may opt
					out of receiving all future promotional e-mails from us by clicking on an
					opt-out or “unsubscribe” link within the promotional e-mail you receive. Please
					understand that if you opt out of receiving promotional correspondence from us,
					we may still contact you in connection with your other relationship,
					activities, transactions and communications with us.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>You may also opt
					out of our SMS messages by texting “STOP” or another applicable keyword to the
					short code applicable to the particular SMS Service (as further described
					below).</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>If you have
					agreed to have us share your Personal Information with third parties so that
					they may contact you about promotions, offers and other information, you can
					revise your preferences by contacting us at support@help.{{ host }}. If you
					do request to have us stop sharing your Personal Information with other
					entities for their direct marketing purposes, such request will only apply as
					of the date of your request, and we will not be responsible for any communications
					that you may receive from entities that received your Personal Information
					prior to such request. In these cases, please contact that entity directly or
					click on the opt-out or “unsubscribe” link that should be included within the
					promotional e-mail you receive from such third party.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>You may opt-out
					of receiving ads from network advertisers by clicking the AdChoices icon on
					advertisements that are sent to you, visiting the opt-out pages on the NAI
					website and the DAA website. Opting out does not prevent you from seeing ads;
					it simply means that network advertisers will no longer collect data for the
					purpose of providing you targeted ads. The DAA and NAI opt-out tools are
					cookie-based. They signal network advertisers so that they do not collect data
					online or deliver specific ads targeting you, and only affect the Internet/web
					browser on the computer where the cookies are installed. These opt-out tools
					will only function if your browser is set to accept third-party cookies. If you
					delete an opt-out cookie or all your cookies from a browser's cookie files,
					change web browsers or change computers, you will no longer be opted out of our
					data collection and ad targeting, and we may place a new cookie unless an
					opt-out cookie is again reset on that browser. Opting out using one browser on
					one computer will not opt you out using any other browser on the same or
					another computer.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_vnuuvfydq7cu"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>8. California Residents: California Privacy Rights</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>California Civil
					Code Section 1798.83, also known as the “Shine The Light” law, permits our
					customers who are California residents to request and obtain from us once a
					year, free of charge, information about the Personal Information we disclosed
					to third parties for direct marketing purposes in the preceding calendar year.
					If you are a California resident and would like a copy of this notice, please <b>contact
					us</b> at support@help.{{ host }} or write to us at “*5 Park Plaza Suite
					1020 (A), Irvine, California, 92614*”. Attn: {{ host }}. Not all
					information sharing is covered by the “Shine The Light” requirements and only
					information on covered sharing will be included in our response. Under
					California law, businesses are only required to respond to a request once
					during any calendar year.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>If you are a
					California resident, then you also have certain rights under the California
					Consumer Privacy Act (“CCPA”) regarding your Personal Information:</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_iub5seecozaf"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(A) Right to Know</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>You have the
					right to know and see what data we have collected about you over the past 12
					months, including:</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>The categories of Personal Information we have collected about
					you;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>The categories of sources from which the Personal Information is
					collected;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>The business or commercial purpose for collecting your Personal
					Information;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>The categories of third parties with whom we have shared your
					Personal Information; and</span></p>

					<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
					margin-left:.5in;text-indent:-.25in;'><span lang=EN
					style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>The specific pieces of Personal Information we have collected
					about you.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_ahvfbr1r2ea7"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(B) Right to Delete</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>You have the
					right to request that we delete the Personal Information we have collected from
					you (and direct our Service Providers to do the same). There are a number of
					exceptions, however, that include, but are not limited to, when the information
					is necessary for us or a third party to do any of the following:</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Complete your transaction;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Provide you a good or service;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Perform a contract between us and you;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Protect your security and prosecute those responsible for
					breaching it;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Fix our system in the case of a bug;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Protect the free speech rights of you or other users;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Comply with the California Electronic Communications Privacy Act
					(Cal. Penal Code § 1546 et seq.);</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Engage in public or peer-reviewed scientific, historical, or
					statistical research in the public interests that adheres to all other
					applicable ethics and privacy laws;</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Comply with a legal obligation; or</span></p>

					<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
					margin-left:.5in;text-indent:-.25in;'><span lang=EN
					style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Make other internal and lawful uses of the information that are
					compatible with the context in which you provided it.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_m4d8o3c0smt3"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>(C) Exercising Your Rights</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>To exercise your
					right to know or your right to delete, please submit a verifiable consumer
					request to us at support@help.{{ host }}. Only you, or someone legally
					authorized to act on your behalf, may make a verifiable consumer request
					related to your Personal Information. You may only make a verifiable consumer
					request to exercise your right to know twice within a 12-month period. The
					verifiable consumer request must:</span></p>

					<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;'><span
					lang=EN style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Provide sufficient information that allows us to reasonably verify
					you are the person about whom we collected Personal Information or an
					authorized representative.</span></p>

					<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
					margin-left:.5in;text-indent:-.25in;'><span lang=EN
					style='font-size:11.5pt;line-height:115%'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span><span lang=EN style='font-size:11.5pt;line-height:115%;
					color:black'>Describe your request with sufficient detail that allows us to
					properly understand, evaluate, and respond to it.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We cannot respond
					to your request or provide you with Personal Information if we cannot verify
					your identity or authority to make the request and confirm the Personal
					Information relates to you.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We will not
					discriminate against you for exercising any of your CCPA rights described
					above. Unless permitted by the CCPA, we will not deny you goods or services;
					charge you different prices or rates for goods or services; provide you a
					different level or quality of goods or services; or suggest that you may
					receive a different price or rate for goods or services or a different level or
					quality of goods or services.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_6rwhrkx6rzhl"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>9. Security Practices</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>We use reasonable
					security efforts to protect the Personal Information in our possession.
					However, no method of transmission or storage of data is 100% secure and we
					will not be responsible for any damage that results from a security breach of
					data or the unauthorized access to or use of information, whether Personal
					Information or Device Identifiable Information. To the extent we provide your
					Personal Information to any third parties, we will request that they use
					reasonable security measures to protect your information.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_74cebaojoki8"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>10. Links to Other Services</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>The Services may
					contain links to other websites and online services. When you link to such
					other sites and services (including by means of clicking on hyperlinks, logos,
					widgets, banners or advertisements), you become subject to their terms of use
					and privacy policies posted thereon and/or applicable thereto. Because we
					cannot control the activities of third parties, please note that we are not
					responsible for the privacy practices of third-party websites. We encourage you
					to be aware of this when you click on a link and leave the Website, and to read
					the privacy policies of every website that collects Personal Information from
					you.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_8bw7xh417bjm"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>11. Additional Information</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>The Services are
					operated and maintained within the U.S. We make no assurances or
					representations of any kind that the Services are suitable for use outside the
					U.S. or in the country in which you reside. The information we collect is kept
					on servers in the U.S. and may be transferred to servers in other countries,
					subject to the terms of this Privacy Policy. We retain the Personal Information
					that we collect for so long as we continue to have a business purpose for it.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>By accessing and
					using a Service, establishing an account, or making a purchase, you consent to
					the processing of your Personal Information as provided in this Privacy Policy.
					You may withdraw that consent by contacting us at support@help.{{ host }}. Please know that
					when you withdraw your consent, we may still use your information to the extent
					necessary to complete a transaction with you or as otherwise permitted by law.
					You are required to provide certain Personal Information in order to use a
					Service, to make a purchase or to take advantage of certain features available
					through the Website. Your choice not to provide this information may prevent
					you from using the Services or may result in a need to terminate your account
					or an inability for you to use certain features. It is not our intent to
					discriminate against you for exercising your rights or the choices available to
					you. But, without certain information, the Website and Services may not work as
					designed.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>If you wish to
					object to or raise a complaint on how we have handled your Personal
					Information, you may </span><span lang=EN style='color:black'><b><span style='font-size:11.5pt;
					line-height:115%;color:#005D77;text-decoration:none'>contact us</span></b></span><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'> at
					support@help.{{ host }}.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>If you are not
					satisfied with our response or believe our processing of your Personal
					Information is not in accordance with law, you may register a complaint with
					the appropriate governmental authority. controls the Personal Information
					collected via the Website and Services.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_k9qsrtamxkdc"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>12. Changes to this Privacy Policy</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>This Privacy
					Policy replaces any earlier version. You should review this Privacy Policy
					periodically as we may modify it from time to time. If we change our Privacy
					Policy in the future, we will post the changed Privacy Policy on the homepage
					of the Website and other locations where the policy was previously posted. You
					will be able to determine what version of the Privacy Policy applies by the
					“Effective Date” placed under the title. Where consistent with applicable law,
					your continued use of the Services after we have changed the Privacy Policy
					signifies your acceptance of the revised terms. Under certain circumstances
					(e.g., with respect to certain material changes to this Privacy Policy), we may
					also elect to notify you through additional means, such as posting a notice on
					the front page of the Website or by means of an e-mail.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_qejke25xavk4"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>13. Children’s Data</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>You must be at
					least 13 years old to use the Website.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>General Rules:
					Although most portions of the Website are directed to general audiences, we do
					not knowingly collect Personal Information from children under 18. If we learn
					we have collected or received Personal Information from a child under 18
					without verification of parental consent, we will endeavor to delete that
					information.</span></p>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Information
					Collected and Parental Rights: If your child wants to register on or
					participate in certain activities in the areas of the Services that are
					directed to children under 18, we will only do so if we have your affirmative
					consent. During the registration process, we may collect certain information
					from your child, including Personal Information such as their name or e-mail
					address, as well as a parent or guardian’s email address in order to obtain
					your affirmative consent. We will then send you an e-mail (which will include a
					link to this Privacy Policy), advising you about the portion of the Services in
					which your child has expressed interest, and request that you reply by e-mail
					if you consent to your child’s participation. In the reply e-mail, we may
					request further Personal Information from you (as defined in the main part of
					this Privacy Policy) about you and/or your child, but we will not request such
					information from your child. We will then send you a confirmatory e-mail of
					your child’s registration. Once your child is registered, we may request them
					to provide general information such as their favorite player. You have the
					right to review your child’s Personal Information, to have that information
					deleted, and/or to refuse to permit its further collection or use. Parents can
					contact the Privacy Administrator at the address or email asset forth at the
					end of this policy and we will respond to their requests.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_gdue0o31eqcg"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>14. Exclusions</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>This Privacy
					Policy does not apply to any Personal Information collected by other than
					Personal Information collected through the Services. This Privacy Policy also
					does not apply to information about businesses or other non-individual users.
					reserves the right to use and disclose aggregated or de-identified information
					for any commercially reasonable purpose.</span></p>

					<h4 style='margin-top:0in;margin-right:0in;margin-bottom:2.0pt;margin-left:
					0in;line-height:139%;page-break-after:auto;'><a
					name="_7p21u6nm1fdm"></a><b><span lang=EN style='font-size:11.0pt;line-height:
					139%;color:black'>15. Contact Us</span></b></h4>

					<p class=MsoNormal style='margin-bottom:12.0pt;'><span
					lang=EN style='font-size:11.5pt;line-height:115%;color:black'>Please feel free
					to <b>contact us</b> if you have questions or concerns about this Privacy
					Policy or if you would like to exercise your applicable data protection rights.</span></p>

					<p class=MsoNormal><span lang=EN>&nbsp;</span></p>

					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import BlogHero from '@/components/shared/heroes/BlogHero';

export default {
    name: 'about-us',
    components: { BlogHero },
    created() {
        this.$store.commit('app/toggleDrawer', false);
    },
    computed: {
        host () {
            return window.location.hostname;
        }
    }
}; 
</script>
